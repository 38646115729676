import React from "react";
import { Table } from "./Table";
import { TableStyles } from "../style/globalStyles";

export const SwingUsersTable = ({ swingusers }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "City",
        accessor: "cityName",
      },
      // {
      //   Header: "Business Name",
      //   accessor: "businessName",
      // },
      {
        Header: "Mobile",
        accessor: "phoneNumber",
      },
      {
        Header: "Role",
        accessor: (user) => {
          return user.role === "driver"
            ? "Car Owner"
            : user.role === "travel_agent"
            ? "Travel Agent"
            : "Consumer";
        },
      },
      // {
      //   Header: "KYC Document",
      //   accessor: "kycDocumentNumber",
      // },
      {
        Header: "Active",
        accessor: "verificationStatus",
      },
      {
        Header: "Documents",
        accessor: (user) => {
          return user.role === "driver"
            ? (
              user.documents.length == 0
                ? "Not Available"
                : user.documents.length == 1
                  ? "Partial" : "Available")
            : "NA"
        },
      },
    ],
    []
  );

  var data = React.useMemo(() => swingusers, []);

  var tableRender = (
    <Table
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          // onClick: (e) => console.log("row clicked", rowInfo),
        };
      }}
      // Pass the page url here to navigate to onClick
      path={"/swinguser"}
      extras={true}
      columns={columns}
      data={data}
    />
  );
  return <TableStyles>{swingusers ? tableRender : " "}</TableStyles>;
};
