import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { connect, useSelector } from "react-redux";
import { fetchSwingUsers } from "../state";
import { GlobalStyle } from "../style/globalStyles";
import { Layout } from "../components/Layout";
import { AgenciesSection as SwingUsersSection } from "../style/agencies";
import { SwingUsersTable } from "../components/SwingUsersTable";
import { BallBeat } from "react-pure-loaders";
function SwingUsersContainer({ swingUsersData, fetchSwingUsers }) {
  const loginData = useSelector((state) => state.auth.user);
  const loginBool = Boolean(loginData.portalUserId);

  useEffect(() => {
    if (!loginBool) {
      navigate("/login");
    }
    // console.log("fetchSwingUsers being called")
    if (swingUsersData.swingusers.length === 0) {
      fetchSwingUsers();
    }
  }, [fetchSwingUsers]);
  console.log("swingUsersData", swingUsersData);
  return (
    <>
      <GlobalStyle />
      <Layout>
        <SwingUsersSection>
          {swingUsersData.loadingswingusers ? (
            <h2>
              Loading Swing Users{" "}
              <span>
                <BallBeat
                  color={"#123abc"}
                  loading={swingUsersData.loadingswingusers}
                />
              </span>{" "}
            </h2>
          ) : swingUsersData.errorswingusers ? (
            <>
              <h2>ERROR ⚠️ FETCHING Swing Users</h2>
              <br />
              <p style={{ color: "grey" }}>{swingUsersData.errorswingusers}</p>
              <br />
              <br />
            </>
          ) : (
            <>
              <h1>Swing Users List</h1>
              <br />
              <SwingUsersTable swingusers={swingUsersData.swingusers} />
            </>
          )}
        </SwingUsersSection>
      </Layout>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    swingUsersData: state.swing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSwingUsers: () => dispatch(fetchSwingUsers()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwingUsersContainer);
